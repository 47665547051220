// Project constants
import { Capacitor } from "@capacitor/core";
import { CONSTANTS as SHARED_CONSTANTS } from "@sushicorp/utils";
import { DEFAULT_VENDORS } from "@sushicorp/utils";
import { ArtisnPlatform, Vendor } from "artisn/types";

import { PickUpOption } from "types/common.types";

import PackageJSON from "../../package.json";

export const DOMAIN = "https://ecommerce.kobe-sushi.com";

export const DEFAULT_PICK_UP_OPTION = {
  id: 0,
  name: "Quiero mi pedido ahora",
  forNow: true
};

export const PICK_UP_OPTIONS: PickUpOption[] = [
  DEFAULT_PICK_UP_OPTION,
  { id: 1, name: "Hora programada", forNow: false }
];

const WHATSAPP_PHONE_NUMBER = "593997912506";

export const DEFAULT_VENDOR: Vendor = DEFAULT_VENDORS[30];

const CONSTANTS = {
  ...SHARED_CONSTANTS,
  TITLE:
    "Noe Sushi Bar fusión japonesa ecuatoriana en Quito, Guayaquil y Cuenca",
  // Project configurations
  ARTISN: {
    ...SHARED_CONSTANTS.ARTISN,
    ACCOUNT_ID: 30,
    DEFAULT_VENDOR,
    VENDORS: [DEFAULT_VENDOR]
  },
  // REST API configurations
  API: {
    ...SHARED_CONSTANTS.API,
    MOCK_SERVICES: false,
    MOCK_WITH_INITIAL_USER_DATA: true
  },
  // Activate or deactivate features
  FEATURE_FLAGS: {
    ...SHARED_CONSTANTS.FEATURE_FLAGS,
    // Allows the user to activate or deactivate the dark mode
    WITH_DARK_MODE: true,
    // Show AnonymousPasswordForm component in Checkout
    WITH_CHECKOUT_SIGNUP: false,
    // Activates the express checkout mode for payphone payments
    WITH_PAYPHONE_EXPRESS_CHECKOUT: false,
    // Show product modal
    WITH_PRODUCT_MODAL: true,
    // Show SelectAddressDropdown component in Navbar
    WITH_SELECT_ADDRESS_DROPDOWN: true,
    // Show share product
    WITH_SHARE_PRODUCT: true,
    // With delivery flow
    WITH_DELIVERY: true,
    // Show coupons
    WITH_COUPONS: true,
    // Show upsale modal
    WITH_UPSALE_MODAL: false,
    // Active the payment method express checkout
    WITH_PAYMENTEZ_EXPRESS_CHECKOUT: true,
    // Show modal merge cart
    WITH_ANONYMOUS_SHOPPING_CART_TOKEN: false,
    // Show categories menu bar
    WITH_CATEGORIES_MENU: false,
    // Package cost
    WITH_PACKAGE_COST: true,
    // Extra products as expandable
    WITH_EXTRA_PRODUCTS_AS_EXPANDABLE: false,
    // Show bottom search
    WITH_BOTTOM_SEARCH: false,
    // Show order now button
    WITH_ORDER_NOW: true,
    // Show links in footer
    WITH_FOOTER_LINKS: false,
    // Have footer links always in a new tab
    WITH_FOOTER_LINKS_NEW_TAB: true,
    // Show vertical categories list
    WITH_VERTICAL_CATEGORIES: true,
    // Show greeting text when login
    WITH_GREETING_TEXT: false,
    // Show bottom responsive buttons
    WITH_BOTTOM_RESPONSIVE_BUTTONS: true,
    // Enable users sync v1
    WITH_USER_SYNC_V1: false,
    // Enable data protection enforcement
    WITH_DATA_PROTECTION: true,
    // Enable talk shop
    WITH_TALK_SHOP: true,
    // Enable change button on shopping cart drawer
    WITH_CHANGE_BUTTON: false,
    // Enable new talkshop EP
    WITH_NEW_TALKSHOP_EP: false
  },
  STORAGE: {
    ...SHARED_CONSTANTS.STORAGE,
    AUTH_METHOD: "auth-method",
    PENDING_ORDER_TOKEN: "artisn-pending-order",
    TRANSFER_ANONYMOUS_ID: "transfer-anonymous-id",
    EXTRA_TOTAL: "extraProductsTotal",
    PAYMENTEZ_TRANSACTION_REFERENCE: "paymentez-transaction-reference",
    PRODUCTS_AMOUNT: "products-amount"
  },
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    hd: 2560
  },
  // General settings
  GENERAL: {
    ...SHARED_CONSTANTS.GENERAL,
    // Platform running the application
    PLATFORM: Capacitor.getPlatform() as ArtisnPlatform,
    // Included environments that should be logged
    INCLUDED_LOGGER_ENVS: ["development", "production"],
    // App version code to enable update and support screen
    VERSION_CODE: PackageJSON.version,
    // Promotion category id
    PROMOTION_CATEGORY: process.env.NEXT_PUBLIC_PROMOTION_CATEGORY ?? "1596",
    // Product fallback image
    PRODUCT_FALLBACK_IMAGE: "/assets/images/products/fallback-img.png",
    // Customer service email
    CUSTOMER_SERVICE_EMAIL: "servicioalcliente@sushicorp.ec",
    // Talkshop token
    TALKSHOP_TOKEN:
      "EAAIftWIlTJcBO5yo1qXksSsbUwRf9oeT0VBmZCt09McXTH7pooRa7QmjohEgtNkAlimZC2JwHThCqrEMXh9vRSdZCmCPVclj4SKcZAK4deECjNe75zSdMcRs5l4ULwRFW19yflfjpuB3qZC52FkPV7MO3TLrC5bwd80zpGZA7wZB1TEdT6biI6b9Pf0",
    // Talkshop x api token
    TALKSHOP_X_API_TOKEN:
      "tlkshpdf399d3060ea71f9bcb9cc63b7341daa9d6d1fbdd57709c095ce8e3e21502550",
    // Talkshop identifier
    TALKSHOP_IDENTIFIER: "469373009598867"
  },
  // Date and time format strings
  DATES_FORMAT: {
    HOUR_MINUTE: "HH:mm",
    COMPLETE_DATE_TIME: "YYYY-MM-DD HH:mm:ss"
  },
  INTEGRATIONS: {
    ...SHARED_CONSTANTS.INTEGRATIONS,
    WHATSAPP: {
      PHONE_NUMBER: WHATSAPP_PHONE_NUMBER,
      RETURN_TO_APP_URL: `https://api.whatsapp.com/send/?phone=${WHATSAPP_PHONE_NUMBER}&text&app_absent=0`
    },
    APP_URL: "https://22xy.short.gy/NOE"
  }
};

export default CONSTANTS;
