import { useCountries, useShoppingCart, useStores } from "@sushicorp/contexts";
import { defaultFunction, formatByCurrency, getImage } from "@sushicorp/utils";
import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import { getShoppingCartProducts, setProduct } from "artisn/shopping-cart";
import { AdditionalInfo, CartProduct } from "artisn/types";
import { Image, useProductCard } from "artisn-ui-react";
import React, { useMemo } from "react";

import { AddToCartNotificationModifiable } from "./AddToCartNotification.styles";
import { AddToCartNotificationStyled } from "./AddToCartNotification.styles";
import { AddToCartNotificationProps as Props } from "./AddToCartNotification.types";
import { defaultImageConfig } from "../AddToWishlistNotification/AddToWishlistNotification.helpers";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import ShoppingCartProduct from "components/Cart/ShoppingCartProduct/ShoppingCartProduct";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";
import useI18n from "hooks/useI18n";
import { dismissAddToCartNotification } from "utils/notifications.utils";

const { PRODUCT_FALLBACK_IMAGE } = CONSTANTS.GENERAL;
const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;

const AddToCartNotification: React.FC<Props> = props => {
  const { className, product, showDrawer = defaultFunction } = props;
  const { amount = 1, modifiable } = props;
  const { isAnonymous = false, uid } = useAuth();
  const t = useI18n();
  const { selectedStore } = useStores();
  const defaultFormatter = useDefaultCurrencyFormatter();
  const { shoppingCart } = useShoppingCart();
  const { selectedCountry } = useCountries();
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && !!talkShopProvider && !!talkShopIdentifier;
  const { decimals } = selectedCountry ?? {};
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const addedProduct = useMemo(() => {
    const { productId } = product ?? {};
    return products?.find(item => item.productId === productId);
  }, [product, products]);
  const productCard = useProductCard(
    addedProduct ?? ({} as CartProduct<AdditionalInfo, AdditionalInfo>),
    {
      decimals
    }
  );
  const { totals: productTotals } = productCard ?? {};
  const { netPrice } = productTotals ?? {};

  const { images, name, description } = product ?? {};
  const { name: shoppingCartName } = shoppingCart ?? {};

  const { url } = getImage(images, PRODUCT_FALLBACK_IMAGE);

  if (modifiable) {
    return (
      <AddToCartNotificationModifiable
        showCloseIcon
        className={`AddToCartNotification ${className}`}
        onClose={dismissAddToCartNotification}
      >
        <p className="AddToCartNotification__name">{t.cart.addedProduct}</p>
        {product ? (
          <ShoppingCartProduct
            product={product}
            onChangeQuantity={(value: number) => {
              if (!uid) return;
              if (!selectedStore) return;

              setProduct(product, {
                amount: value,
                shoppingCartName,
                anonymous: isAnonymous,
                accountId: ACCOUNT_ID,
                customerId: uid,
                store: selectedStore
              });
            }}
            initialQuantity={amount}
          />
        ) : null}
        <div className="AddToCartNotification__buttons">
          <Button
            className="AddToCartNotification__button AddToCartNotification__button--show-cart"
            onClick={showDrawer}
            type="BORDER"
            color="white"
          >
            {t.cart.showCart}
          </Button>
          <CartPayButton shoppingCart={shoppingCart} hideAddressCheckbox />
        </div>
      </AddToCartNotificationModifiable>
    );
  }

  return (
    <AddToCartNotificationStyled
      className={`AddToCartNotification ${className}`}
      showCloseIcon
      onClose={dismissAddToCartNotification}
    >
      <div className="AddToCartNotification__head">{t.cart.addedProduct}</div>
      <div className="AddToCartNotification__content">
        {images?.[0] ? (
          <Image
            image={url}
            config={defaultImageConfig}
            alt="Action icon"
            className="AddToCartNotification__image"
          />
        ) : null}
        <div className="AddToCartNotification__summary">
          <p className="AddToCartNotification__name">
            {toSentence(name ?? "")}
          </p>
          <p className="AddToCartNotification__description">
            {toSentence(description ?? "")}
          </p>
          <p className="AddToCartNotification__product-amount">
            {formatByCurrency(netPrice, defaultFormatter)}
          </p>
        </div>
      </div>
      <div className="AddToCartNotification__actions">
        <Button
          className="AddToCartNotification__button AddToCartNotification__button--cart"
          color="white"
          onClick={showDrawer}
        >
          {t.cart.showCart}
        </Button>
        {!talkShop ? (
          <CartPayButton
            shoppingCart={shoppingCart}
            className="AddToCartNotification__button AddToCartNotification__button--checkout"
            hideAddressCheckbox
          />
        ) : null}
      </div>
    </AddToCartNotificationStyled>
  );
};

AddToCartNotification.defaultProps = {};

export default AddToCartNotification;
