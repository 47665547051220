import styled from "styled-components";

import { AddressCheckboxStyledProps as Props } from "./AddressCheckbox.types";

const AddressCheckboxStyled = styled.div<Props>`
  opacity: ${props => (props.disabled ? "0.5" : "1")};

  .AddressCheckbox {
    &__text {
      color: var(--palette-white);
      font-size: 1.4rem;
      font-weight: 400;

      &--bold {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
`;
export default AddressCheckboxStyled;
