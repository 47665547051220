import styled from "styled-components";

import { CartPayButtonStyledProps as Props } from "./CartPayButton.types";

const CartPayButtonStyled = styled.div<Props>`
  width: 100%;
  bottom: 0;
  z-index: 3;

  &&.CartPayButton {
    .Cart__summary__button {
      width: 100%;
      padding: 1.6rem 3.2rem;
      font-family: inherit;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      display: flex;
      justify-content: space-between;
      border-radius: 1.8rem;
    }

    .Cart {
      &__address-checkbox {
        padding-bottom: 1.6rem;
      }
    }
  }

  .CartPayButton {
  }
`;

export default CartPayButtonStyled;
